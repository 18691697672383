import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import store from "@/store";

function lazyLoad(view) {
      return () => import(`@/components/pages/${view}.vue`)
}

const routes = [
      {
            path: "*",
            name: "Error404Page",
            meta: { layout: "default", title: "Page introuvable" },
            component: lazyLoad('errors/404'),
      },
      {
            path: '/',
            redirect: '/files/files-dashboard',
      },
      {
            path: '/login',
            name: 'login',
            component: lazyLoad('login/LoginPage'),
            meta: { layout: "default", title: "login" },
            props: true,
      },
      // {
      //       path: '/signup',
      //       name: 'signup',
      //       component: lazyLoad('signup/SignupPage'),
      //       meta: { layout: "default", title: "signup" },
      //       props: true,
      // },
      {
            path: '/reset-password',
            name: 'resetPassword',
            component: lazyLoad('forgetPassword/ResetPassword'),
            meta: { layout: "default", title: "resetPassword" },
            props: true,
      },
      {
            path: '/forget-password',
            name: 'forgetPassword',
            component: lazyLoad('forgetPassword/ForgetPassword'),
            meta: { layout: "default", title: "forgetPassword" },
            props: true,
      },
      {
            path: '/home',
            name: 'homePage',
            component: lazyLoad('home/Home'),
            meta: { layout: "default", title: "home", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/files-dashboard',
            name: 'filesDashboard',
            component: lazyLoad('filesDashboard/filesList/FilesList'),
            meta: { layout: "default", title: "files dashboard", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/new-file',
            name: 'newFile',
            component: lazyLoad('filesDashboard/newFile/NewFile'),
            meta: { layout: "default", title: "new file", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/transaction',
            name: 'transaction',
            component: lazyLoad('filesDashboard/transaction/Transaction'),
            meta: { layout: "default", title: "transaction", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/sellers',
            name: 'sellersDashboard',
            component: lazyLoad('filesDashboard/sellers/Sellers'),
            meta: { layout: "default", title: "sellers dashboard", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/sellers/new-seller',
            name: 'newSeller',
            component: lazyLoad('filesDashboard/sellers/NewSeller'),
            meta: { layout: "default", title: "new-seller", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/sellers/email-detail',
            name: 'emailDetail',
            component: lazyLoad('filesDashboard/sellers/emailDetails/emailDetails'),
            meta: { layout: "default", title: "emailDetail", requiresAuth: false },
            props: true,
      },
      {
            path: '/files/buyers/email-detail',
            name: 'emailDetail',
            component: lazyLoad('filesDashboard/buyers/emailDetails/emailDetails'),
            meta: { layout: "default", title: "emailDetail", requiresAuth: false },
            props: true,
      },
      {
            path: '/files/buyers',
            name: 'buyers',
            component: lazyLoad('filesDashboard/buyers/Buyers'),
            meta: { layout: "default", title: "buyers", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/buyers/new-buyer',
            name: 'newBuyer',
            component: lazyLoad('filesDashboard/buyers/NewBuyer'),
            meta: { layout: "default", title: "new-buyer", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/properties',
            name: 'properties',
            component: lazyLoad('filesDashboard/properties/Properties'),
            meta: { layout: "default", title: "properties", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/properties/new',
            name: 'newProperty',
            component: lazyLoad('filesDashboard/properties/newProperty'),
            meta: { layout: "default", title: "newProperty", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/orderToPay',
            name: 'orderToPay',
            component: lazyLoad('filesDashboard/orderToPay/OrderToPay'),
            meta: { layout: "default", title: "order to pay", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/trustReconciliation',
            name: 'trustReconciliation',
            component: lazyLoad('filesDashboard/trustReconciliation/TrustReconciliation'),
            meta: { layout: "default", title: "trust reconciliation", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/documentManager',
            name: 'documentManager',
            component: lazyLoad('filesDashboard/documentManager/DocumentManager'),
            meta: { layout: "default", title: "document manager", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/buyerStatementAdjustments',
            name: 'buyerStatementAdjustments',
            component: lazyLoad('filesDashboard/buyerStatementAdjustments/BuyerStatementAdjustments'),
            meta: { layout: "default", title: "buyer statement adjustments", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/sellerStatementAdjustments',
            name: 'sellerStatementAdjustments',
            component: lazyLoad('filesDashboard/sellerStatementAdjustments/SellerStatementAdjustments'),
            meta: { layout: "default", title: "seller Statement adjustments", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/accounts',
            name: 'accounts',
            component: lazyLoad('filesDashboard/accounts/AccountsList'),
            meta: { layout: "default", title: "accounts list", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/accounts/accountDetail',
            name: 'accounts',
            component: lazyLoad('filesDashboard/accounts/AccountDetail'),
            meta: { layout: "default", title: "accounts detail", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/titleInsurance',
            name: 'titleInsurance',
            component: lazyLoad('filesDashboard/titleInsurance/TitleInsurance'),
            meta: { layout: "default", title: "title tnsurance", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/titleInsurance/chicagoTitleInsuranceCompany',
            name: 'chicagoTitleInsuranceCompany',
            component: lazyLoad('filesDashboard/titleInsurance/chicagoTitleInsuranceCompany/ChicagoTitleInsuranceCompany'),
            meta: { layout: "default", title: "chicago titleInsurance company", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/titleInsurance/firstCanadianTitle',
            name: 'firstCanadianTitle',
            component: lazyLoad('filesDashboard/titleInsurance/firstCanadianTitle/FirstCanadianTitle'),
            meta: { layout: "default", title: "first canadian title", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/mortgages',
            name: 'mortgages',
            component: lazyLoad('filesDashboard/mortgages/MortgagesComponent'),
            meta: { layout: "default", title: "mortgages", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/mortgages/new',
            name: 'newMortgage',
            component: lazyLoad('filesDashboard/mortgages/NewMortgage'),
            meta: { layout: "default", title: "new mortgage", requiresAuth: true },
            props: true,
      },
      {
            path: '/account-defaults',
            name: 'accountDefaults',
            component: lazyLoad('accountMananger/AccountsList'),
            meta: { layout: "default", title: "account defaults", requiresAuth: true, requiresNonConveyancer: true },
            props: true,
      },
      {
            path: '/account-defaults/details',
            name: 'accountDefaultsDetails',
            component: lazyLoad('accountMananger/AccountDetail'),
            meta: { layout: "default", title: "account defaults details", requiresAuth: true, requiresNonConveyancer: true },
            props: true,
      },
      {
            path: '/files/web-filling',
            name: 'webFilling',
            component: lazyLoad('filesDashboard/webFillings/WebFillings'),
            meta: { layout: "default", title: "web filling", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/web-filling/formA',
            name: 'webFillingFormA',
            component: lazyLoad('filesDashboard/webFillings/formA/FormA'),
            meta: { layout: "default", title: "web filling form A", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/web-filling/formB',
            name: 'webFillingFormB',
            component: lazyLoad('filesDashboard/webFillings/formB/FormB'),
            meta: { layout: "default", title: "web filling form B", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/web-filling/formC-release',
            name: 'webFillingFormCRelease',
            component: lazyLoad('filesDashboard/webFillings/formCRelease/FormCRelease'),
            meta: { layout: "default", title: "web filling form Form C Release", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/web-filling/formC-charge',
            name: 'webFillingFormCCharge',
            component: lazyLoad('filesDashboard/webFillings/formCCharge/FormCCharge'),
            meta: { layout: "default", title: "web filling form Form C charge", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/web-filling/form-17-cancellation',
            name: 'webFillingFormCancellation',
            component: lazyLoad('filesDashboard/webFillings/form17Cancellation/Form17Cancellation'),
            meta: { layout: "default", title: "web filling form Form 17 cancellation", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/web-filling/form-17-charge',
            name: 'webFillingFormCharge',
            component: lazyLoad('filesDashboard/webFillings/form17Charge/Form17Charge'),
            meta: { layout: "default", title: "web filling form Form 17 charge", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/web-filling/form-17-fee',
            name: 'webFillingFormFee',
            component: lazyLoad('filesDashboard/webFillings/form17FreeSimple/Form17FreeSimple'),
            meta: { layout: "default", title: "web filling form Form 17 fee", requiresAuth: true },
            props: true,
      },
      {
            path: '/files/web-filling/form-pttReturn',
            name: 'webFillingFormPttReturn',
            component: lazyLoad('filesDashboard/webFillings/pttReturn/PttReturn'),
            meta: { layout: "default", title: "web filling form Form PttReturn", requiresAuth: true },
            props: true,
      },
      {
            path: '/file-scheduler',
            name: 'fileScheduler',
            component: lazyLoad('fileScheduler/FileScheduler'),
            meta: { layout: "default", title: "file scheduler", requiresAuth: true },
            props: true,
      },
      {
            path: '/template-manager',
            name: 'templateManager',
            component: lazyLoad('templateManager/Templates'),
            meta: { layout: "default", title: "template manager", requiresAuth: true },
            props: true,
      },
      {
            path: '/template-manager/new-template',
            name: 'templateManager',
            component: lazyLoad('templateManager/NewTemplate'),
            meta: { layout: "default", title: "create template", requiresAuth: true },
            props: true,
      },
      {
            path: '/admin/users',
            name: 'users',
            component: lazyLoad('admin/users/Users'),
            meta: { layout: "default", title: "users", requiresAuth: true, requiresNonConveyancer: true  },
            props: true,
      },
      {
            path: '/admin/organization',
            name: 'organization',
            component: lazyLoad('admin/organization/Organization'),
            meta: { layout: "default", title: "organization", requiresAuth: true, requiresNonConveyancer: true, requiresNonLawyer: true },
            props: true,
      },
      {
            path: '/profile',
            name: 'profile',
            component: lazyLoad('profile/Profile'),
            meta: { layout: "default", title: "profile", requiresAuth: true },
            props: true,
      },
      {
            path: '/template-manager/new-instance',
            name: 'templateManager',
            component: lazyLoad('templateManager/CreateInstance'),
            meta: { layout: "default", title: "create Instance" , requiresAuth: true },
            props: true,
      },
      {
            path: '/template-manager/instance',
            name: 'templateManager',
            component: lazyLoad('templateManager/Instances'),
            meta: { layout: "default", title: "instance List" , requiresAuth: true },
            props: true,
      },
];

const router = new VueRouter({
      mode: 'history',
      linkActiveClass: 'active-link',
      linkExactActiveClass: 'exact-active-link',
      routes
});


router.beforeEach((toRoute, fromRoute, next) => {
      window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'homePage';
      if (toRoute.matched.some(record => record.meta.requiresAuth)) {
            // this route requires auth, check if logged in
            // if not, redirect to login page.
            if (!store.getters.accessToken && !localStorage.getItem('accessToken') && !toRoute.query.token) {
                  next({ name: 'login' })
            } else {
                  next(); // go to wherever I'm going
            }
      } else if (toRoute.matched.some(record => record.meta.requiresNonLawyer)) {
            if ((store.state.userRole || localStorage.getItem('userRole')) !== 'Lawyer') {
                  next({ name: 'filesDashboard' })
            } else {
                  next(); // go to wherever I'm going
            }
      } else if (toRoute.matched.some(record => record.meta.requiresNonConveyancer)) {
            if ((store.state.userRole || localStorage.getItem('userRole')) === 'Conveyancer') {
                  next({ name: 'filesDashboard' })
            } else {
                  next(); // go to wherever I'm going
            }
      } else {
            next(); // does not require auth, make sure to always call next()!
      }
      next();
})

export default router;