<template>
    <cv-header aria-label="Carbon header">
        <cv-header-menu-button aria-label="Header menu" aria-controls="side-nav" v-if="isShowSideBar" />

        <div class="main-header d-flex justify-content-between align-items-center w-100">
            <cv-header-name href="javascript:void(0)">
                Lumins
            </cv-header-name>

            <cv-search class="header-search" :light="false" placeholder="Search input text">
            </cv-search>

            <h4 class="username pl-3" v-if="isLogin">{{ username }}</h4>

            <ul class="header-right text-right px-3 d-flex">
                <li class="ml-4">
                    <a href="#" class="color-white">
                        <Help />
                    </a>
                </li>
                <li class="ml-4">
                    <a href="#" class="color-white">
                        <Notification />
                    </a>
                </li>
                <li class="ml-4 user" v-if="isLogin">
                    <a href="#" class="color-white">
                        <User />
                    </a>
                    <div class="user-detail-popup">
                        <div class="d-flex align-items-center justify-content-between user-details">
                            <h3>
                                {{ username }}
                            </h3>
                            <div class="user-image">
                                <User />
                            </div>
                        </div>
                        <ul>
                            <li>
                                <router-link to="/profile">Profile</router-link>
                            </li>
                            <li class="logout" @click="openLogoutDialog()">
                                <span>Log out</span>
                                <ArrowRight />
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>

        </div>

        <template v-slot:left-panels v-if="isShowSideBar">
            <cv-side-nav id="side-nav" rail :class="!isShoBaseSidenav() ? 'dual-side-nav' : ''">
                <cv-side-nav-items>

                    <cv-side-nav-link to="/dashboard">
                        <template v-slot:nav-icon>
                            <Dashboard />
                        </template>
                        Dashboard
                    </cv-side-nav-link>

                    <span class="seprator"></span>

                    <cv-side-nav-link to="/admin/users" v-if="userRole !== 'Conveyancer'">
                        <template v-slot:nav-icon>
                            <User />
                        </template>
                        Users
                    </cv-side-nav-link>

                    <cv-side-nav-link to="/admin/organization" v-if="userRole !== 'Lawyer' && userRole !== 'Conveyancer'">
                        <template v-slot:nav-icon>
                            <Enterprise />
                        </template>
                        Organizations
                    </cv-side-nav-link>

                    <span class="seprator" v-if="userRole !== 'Conveyancer'"></span>

                    <cv-side-nav-link to="/files/files-dashboard">
                        <template v-slot:nav-icon>
                            <Folders />
                        </template>
                        File Manager
                    </cv-side-nav-link>

                    <cv-side-nav-link to="/file-scheduler">
                        <template v-slot:nav-icon>
                            <EventSchedule />
                        </template>
                        Scheduler
                    </cv-side-nav-link>

                    <cv-side-nav-link to="/template-manager">
                        <template v-slot:nav-icon><Template /></template>
                        Template
                    </cv-side-nav-link>

                    <cv-side-nav-link to="/account-defaults" v-if="userRole !== 'Conveyancer'">
                        <template v-slot:nav-icon>
                            <Account />
                        </template>
                        Account Manager
                    </cv-side-nav-link>

                    <span class="seprator"></span>

                    <cv-side-nav-link to="/settings">
                        <template v-slot:nav-icon>
                            <Settings />
                        </template>
                        Settings
                    </cv-side-nav-link>

                    <cv-side-nav-link to="/support">
                        <template v-slot:nav-icon>
                            <Help />
                        </template>
                        Support
                    </cv-side-nav-link>

                </cv-side-nav-items>
            </cv-side-nav>
        </template>

        <ConfirmDialogue ref="headerConfirmDialogue"></ConfirmDialogue>
    </cv-header>
</template>
<script>
import User from '@carbon/icons-vue/es/user/24';
import Notification from '@carbon/icons-vue/es/notification--new/24';
import Dashboard from '@carbon/icons-vue/es/dashboard/24';
import ArrowRight from '@carbon/icons-vue/es/arrow--right/24';
import Folders from '@carbon/icons-vue/es/folders/24';
import EventSchedule from '@carbon/icons-vue/es/event--schedule/24';
import Settings from '@carbon/icons-vue/es/settings/24';
import Template from '@carbon/icons-vue/es/template/24';
import Account from '@carbon/icons-vue/es/account/24';
import Help from '@carbon/icons-vue/es/help/24';
import Enterprise from '@carbon/icons-vue/es/enterprise/24';

import store from '@/store';
import ConfirmDialogue from '@/components/common/ConfirmDialogue.vue';

import { logout } from '@/services/axios/auth/logout.service';

export default {
    name: 'headerComponent',
    components: {
        User,
        Notification,
        Dashboard,
        Template,
        Folders,
        ArrowRight,
        EventSchedule,
        Settings,
        Help,
        Account,
        Enterprise,
        ConfirmDialogue
    },

    data() {
        return {
            cuerrentRoute: '',
            username: '',
            userRole: '',
            isLogin: '',

            isShowSideBar: true
        }
    },

    watch: {
        '$route'(to) {
            this.cuerrentRoute = to;
            this.isShoBaseSidenav();
        }
    },

    methods: {
        isShoBaseSidenav() {
            this.isLogin = (store.state.accessToken || localStorage.getItem('accessToken')) ? true : false;
            this.userRole = (store.state.userRole || localStorage.getItem('userRole'));
            this.username = (store.state.userName || localStorage.getItem('userName'));

            const type = this.cuerrentRoute.query?.type;
            if ((type && type === 'seller') || (type && type === 'buyer') || this.cuerrentRoute?.name == 'templateManager') {
                this.isShowSideBar = true;
                return false;

            } else if (this.cuerrentRoute.name === 'login' || this.cuerrentRoute.name === 'signup' || this.cuerrentRoute.name === 'emailDetail') {
                this.isShowSideBar = false;
                return false;
            }
            else {
                this.isShowSideBar = true;
                return true;
            }
        },

        async openLogoutDialog() {
            const ok = await this.$refs.headerConfirmDialogue.show({
                title: 'Logout User',
                message: 'Are you sure?',
                okButton: 'Yes!',
            })
            if (ok) {
                this.logoutUser();
            }
        },

        logoutUser() {
            logout().then(res => {
                if (!res) {
                    return;
                }

                store.commit('mutationer', { accessToken: '' });
                localStorage.clear();

                this.$router.push({ path: "/login" });

                store.commit('mutationer', { toastSubtitle: (res?.data?.message), isShowToast: true, kind: 'success' });

            }).catch(e => {
                console.log(e)
            })
        }
    },

    mounted() {
        const unwatch = this.$watch(
            () => this.$route,
            (route) => {
                this.cuerrentRoute = route;

                this.isShoBaseSidenav();
                unwatch();
            });
    }

}
</script>

<style lang="scss">
.bx--header {
    z-index: 9999;
}

.bx--header__menu-toggle {
    z-index: 9999;
}

.bx--header__action--active {
    z-index: 9;
}

.seprator {
    background-color: #E0E0E0;
    height: 1px;
    margin: 5px auto;
    width: 70%;
    display: block;
}

.bx--side-nav--ux .bx--side-nav__item {
    padding: 2px 0;
}

.main-header {
    .username {
        font-size: 17px;
        color: #f5f5f5;
    }

    .header-search {
        .bx--search-input {
            border: none;
            background-color: #262626;
            color: #f5f5f5;
            outline: none;
        }

        .bx--search-magnifier-icon {
            fill: #f5f5f5;
        }

        .bx--search-close {
            background-color: #f5f5f5;

            &:hover {
                svg {
                    fill: #262626
                }
            }

            svg {
                fill: #f5f5f5;
            }

            &::before {
                background-color: transparent;
            }
        }
    }

    .user-detail-popup {
        width: 280px;
        background-color: #262626;
        position: fixed;
        right: 0;
        top: 3rem;
        visibility: hidden;
        opacity: 0;
        transition: 0.3s;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .5);

        .user-details {
            padding: 20px;

            .user-image {
                height: 60px;
                width: 60px;
                background-color: #525252;
                border-radius: 50%;
                padding: 18px;

                svg {
                    fill: #fff
                }
            }

            h3 {
                font-size: 20px;
                width: 60%;
                text-align: left;
                color: #fff;
            }
        }

        ul {
            li {
                padding: 0 20px 20px 20px;
                font-size: 17px;
                text-align: left;

                &.logout {
                    cursor: pointer;
                    background-color: #525252;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #fff;

                    svg {
                        fill: #fff;
                    }

                    padding-bottom: 0;
                    height: 50px;
                }
            }
        }
    }

    .user {
        &:hover {
            .user-detail-popup {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
</style>