import axios from 'axios';
import store from '@/store';
import router from '@/router';

const httpClient = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      headers: {},
});

// eslint-disable-next-line no-unused-vars
let numberOfAjaxCAllPending = 0;

httpClient.interceptors.request.use((config) => {
      const token = store.state.accessToken || localStorage.getItem('accessToken');
      token ? config.headers.Authorization =  'Bearer' + ' ' +token : '';

      numberOfAjaxCAllPending ++;
      store.commit('mutationer', { isLoading: true })
      return config;
}, (error) => {
      return Promise.reject(error);
});

httpClient.interceptors.response.use(
      (response) => {
            numberOfAjaxCAllPending --;

            if(numberOfAjaxCAllPending == 0)
                  store.commit('mutationer', { isLoading: false });

            return response
      },
      (error) => {
            numberOfAjaxCAllPending --;

            if(numberOfAjaxCAllPending == 0)
                  store.commit('mutationer', { isLoading: false });

            store.commit('mutationer', { isLoading: false });
            Promise.reject(error);

            if(error.code !== 'ERR_NETWORK') {
                  store.commit('mutationer', { toastSubtitle: (error?.response?.data?.message || error?.response?.data?.result?.message || "somthing went wrong") , isShowToast: true , kind: 'error' });
            }

            if(error?.response?.status === 401) {
                  localStorage.removeItem('accessToken');
                  store.commit('mutationer', { accessToken: '' });

                  router.push({ name: "login" });
            }
      }
);

export default httpClient;