<template>
      <div class="confirm-dialog">
            <cv-modal :visible="confirmDialogVisible" @modal-hidden="_cancel" size="xs" @secondary-click="_cancel" @primary-click="_confirm">
                  <template slot="label">{{ title }}</template>
                  <template slot="title">{{ message }}</template>
      
                  <template slot="secondary-button">{{ cancelButton }}</template>
                  <template slot="primary-button">{{ okButton }}</template>
            </cv-modal>
      </div>
</template>

<script>

export default {
      name: "ConfirmDialogue",

      data: () => ({
            // Parameters that change depending on the type of dialogue
            title: undefined,
            message: undefined, // Main text content
            okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
            cancelButton: "No", // text for cancel button

            // Private variables
            resolvePromise: undefined,
            rejectPromise: undefined,

            confirmDialogVisible: false
      }),

      methods: {
            show(opts = {}) {
                  this.title = opts.title;
                  this.message = opts.message;
                  this.okButton = opts.okButton;
                  if (opts.cancelButton) {
                        this.cancelButton = opts.cancelButton;
                  }
                  // Once we set our config, we tell the popup modal to open
                  this.confirmDialogVisible = true;
                  // Return promise so the caller can get results
                  return new Promise((resolve, reject) => {
                        this.resolvePromise = resolve;
                        this.rejectPromise = reject;
                  });
            },

            _confirm() {
                  this.confirmDialogVisible = false;
                  this.resolvePromise(true);
            },

            _cancel() {
                  this.confirmDialogVisible = false;
                  this.resolvePromise(false);
                  // Or you can throw an error
                  // this.rejectPromise(new Error('User cancelled the dialogue'))
            },
      },
};
</script>

<style lang="scss">  
.confirm-dialog{
      .bx--modal.is-visible .bx--modal-container{
            min-height: 210px!important;
      }

      .bx--modal-content{
            display: none;
      }
}
</style>