<template>
  <div class="lumins-main">

    <Header />

    <main>
      <router-view></router-view>
    </main>

    <cv-loading
        v-show="isLoading"
        :active="true"
        :overlay="true"></cv-loading>
        
    <ToastNotification :kind="toastKind" :subTitle="toastSubtitle" :isVisible="isShowToast" v-if="isShowToast" />

  </div>
</template>

<script>

import Header from "@/components/common/Header.vue";
import ToastNotification from "@/components/common/ToastNotification.vue";

export default {
    name: 'App',
    
    components: { 
        ToastNotification,
        Header 
    },

    computed: {
        toastSubtitle: {
            get(){
                return this.$store?.state?.toastSubtitle;
            }
        },

        isLoading: {
            get(){
                return this.$store?.state?.isLoading;
            }
        },

        isShowToast: {
            get(){
                return this.$store?.state?.isShowToast;
            }
        },

        toastKind: {
            get(){
                return this.$store?.state?.kind;
            }
        }
    },

    watch: {
        isShowToast() {
            setTimeout(() => {
                this.$store.commit('mutationer', { toastSubtitle: '' , isShowToast: false , kind: '' });
            }, 5000);
        }
    },
  
}

</script>