import Vue from 'vue';
import App from './App.vue';
import store from "@/store";
import router from "@/router";
import CarbonComponentsVue from '@carbon/vue';
import VuePdfApp from "vue-pdf-app";

Vue.component("vue-pdf-app", VuePdfApp);
Vue.use(CarbonComponentsVue);
Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app')
