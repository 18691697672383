<template>
    <div class="toast">
        <transition name="fade">
            {{ kind }}
            <cv-toast-notification v-if="visisble" 
                :kind="kind"
                :sub-title="subTitle"
                :low-contrast="false"
                :hide-close-button="false" @close="close()"></cv-toast-notification>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'toastNotification',

        props: {
            kind: String,
            subTitle: String,
            isVisible: Boolean,
        },

        data() {
            return {
                visisble: this.isVisible,
            }
        },

        methods: {
            close() {
                this.visisble = false;
            },
        },
    }
</script>